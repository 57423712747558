import React, { useState, useEffect } from "react";

export const SoonPage = () => {
  const [countdownDate, setCountdownDate] = useState(new Date("2024/10/12"));
  const [countdownValues, setCountdownValues] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // const intervalId = setInterval(() => {
    //   const now = new Date();
    //   const timeLeft = countdownDate.getTime() - now.getTime();

    //   if (timeLeft >= 0) {
    //     const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    //     const hours = Math.floor(
    //       (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    //     );
    //     const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    //     const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    //     setCountdownValues({ days, hours, minutes, seconds });
    //   } else {
    //     clearInterval(intervalId);
    //   }
    // }, 1000);
    const intervalId = setInterval(() => {
      const now = new Date();
      const timeLeft = countdownDate.getTime() - now.getTime();
    
      if (timeLeft >= 0) {
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    
        // Функция для добавления ведущего нуля
        const formatWithLeadingZero = (num) => (num < 10 ? `0${num}` : num);
    
        setCountdownValues({
          days: formatWithLeadingZero(days),
          hours: formatWithLeadingZero(hours),
          minutes: formatWithLeadingZero(minutes),
          seconds: formatWithLeadingZero(seconds),
        });
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdownDate]);

  return (
    <main className="main-06">
      <div className="main-wrapper demo-06">
        <div className="hero-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="heading">
                  <h1 className="text-white">Coming Soon</h1>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7">
                <div className="countdown d-flex">
                  <div className="single-count-content">
                    <span className="count">{countdownValues.days}</span>
                    <p className="text">Days</p>
                  </div>
                  <div className="single-count-content">
                    <span className="count">{countdownValues.hours}</span>
                    <p className="text">Hours</p>
                  </div>
                  <div className="single-count-content">
                    <span className="count">{countdownValues.minutes}</span>
                    <p className="text">Minutes</p>
                  </div>
                  <div className="single-count-content">
                    <span className="count">{countdownValues.seconds}</span>
                    <p className="text">Seconds</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-7"></div>
            <div className="col-md-5">
              <div className="credit">
                <p>
                  <a rel="nofollow" target="_blank" href="https://dagger.uz">
                    All Rights Reserved
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
