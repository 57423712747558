import React from "react";
import Cookies from "universal-cookie";
import CurrencyFormat from "react-currency-format";
import { withRouter } from "react-router-dom";

import OrderCard from "../common/order-card";

import { DeleteCart, ClearCart } from "../Actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { PrimaryButton, SecondaryButton } from "../assets/static/js/Button";

const cookies = new Cookies();

class Cart extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {
      cart: [],
      show: false,
    };
    this._isMounted = false;
    this.sortedDay = [];
    this.clearCookie = this.clearCookie.bind(this);
    const now = new Date();
    this.expires = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
  }

  // Fetch the cart data from cookies and set it to the component state
  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    let data = [];
    this._isMounted = true;
    if (cookies.get("Cart")) {
      data = cookies.get("Cart");
    }
    this._isMounted &&
      this.setState({
        cart: data,
      });

    // Compute the cart price and delivery day
    this.cartPrice(data);
    this.takeDay(data);
  }

  // Decrease the count of an item in the cart
  decrement(id) {
    let tmp = this.state.cart.find((format) => format.id === id);
    if (tmp.count > 1) {
      tmp.count -= 1;
      const array = [...this.state.cart, tmp];
      const withoutDuplicate = [...new Set(array)];
      this._isMounted &&
        this.setState({
          cart: withoutDuplicate,
        });
    }
    cookies.set("Cart", this.state.cart, { maxAge: this.expires });

    // Recompute the cart price and delivery day
    this.cartPrice(this.state.cart);
    this.takeDay(this.state.cart);
  }
  // Increase the count of an item in the cart
  increment(id) {
    let tmp = this.state.cart.find((format) => format.id === id);

    if (tmp.units.length > tmp.count) {
      tmp.count += 1;
      const array = [...this.state.cart, tmp];
      const withoutDuplicate = [...new Set(array)];
      this._isMounted &&
        this.setState({
          cart: withoutDuplicate,
        });
      cookies.set("Cart", this.state.cart, { maxAge: this.expires });

      // Recompute the cart price and delivery day
      this.cartPrice(this.state.cart);
      this.takeDay(this.state.cart);
    }
  }
  // Delete an item from the cart
  deleteItem(e, id) {
    e.preventDefault();
    const updatedData = this.state.cart.filter((format) => format.id !== id);
    this._isMounted &&
      this.setState({
        cart: updatedData,
      });
    cookies.set("Cart", updatedData, { maxAge: this.expires });

    // Recompute the cart price and delivery day
    this.cartPrice(updatedData);
    this.takeDay(updatedData);
    this.props.DeleteCart(id);
  }
  // Clear the cart cookie and state and redirected to home page
  clearCookie = () => {
    cookies.set("Cart", []);
    this._isMounted &&
      this.setState({
        cart: [],
      });
    this.cartPrice(this.state.cart);
    this.takeDay(this.state.cart);
    this.props.ClearCart();
    this.totalPrice = 0;
    this.hideModal();
    this.props.history.push("/");
  };

  // Function for computing cart's total price and total discount
  cartPrice = (data) => {
    let finalPrice = 0;
    let totalDiscount = 0;

    data.map((item) => {
      let price =
        item.price *
        item.count *
        ((item.size.type === 1 || item.size.type === 0
          ? item.size.length * item.size.width
          : parseInt(item.length) * item.size.width) /
          10000);
      finalPrice += price;
      return finalPrice;
    });
    data.map((item) => {
      let discount =
        item.discount *
        item.count *
        ((item.size.type === 1 || item.size.type === 0
          ? item.size.length * item.size.width
          : parseInt(item.length) * item.size.width) /
          10000);
      totalDiscount += discount;
      return totalDiscount;
    });

    this.totalPrice = finalPrice;
    this.discount = totalDiscount;
  };

  // Function for computing a maxmimum deliveryday
  takeDay = (data) => {
    let day = [];
    data.map((item) => {
      for (let i = 0; i < item.count; i++) {
        day.push(item.units[i]);
      }
      return item;
    });

    this.delivery_day = Math.max(...day);
  };
  // show the permittion modal for deleteing all elements from cart
  showModal = () => {
    this._isMounted &&
      this.setState({
        show: true,
      });
  };
  // hide modal
  hideModal = () => {
    this._isMounted &&
      this.setState({
        show: false,
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { t } = this.props;
    return (
      <div className="main py-3">
        <div className="container basket pt-2">
          <div className="basket-left p-2">
            <div className="upper-info">
              <h1 className="text-xl">{t("cart.text")}</h1>
              {/* button for opening modal in order to delete all elemnts off cart */}
              {this.state.cart.length !== 0 && (
                <span
                  className="text-sm link pointer"
                  onClick={() => this.showModal()}
                >
                  {t("deleteAll.text")}
                </span>
              )}
            </div>
            <div className="item-cards">
              {this.state.cart.length === 0 && (
                <p
                  style={{
                    color: "red",
                    fontWeight: "700",
                    textAlign: "center",
                    padding: "15px",
                    fontSize: "22px",
                  }}
                >
                  {t("notFound.text")}
                </p>
              )}
              {/* itterating a cart elements */}
              {this.state.cart.map((item) => {
                return (
                  <div className="single_card_item mt-2" key={item.id}>
                    <div className="single_cart_item">
                      <img src={item.image} alt={item.name} />
                      <div className="item_cart_info px-2">
                        <div className="cancel">
                          <h1 className="text-lg">{item.col_name}</h1>
                          <button
                            className="item-button"
                            onClick={(e) => this.deleteItem(e, item.id)}
                            style={{ backgroundColor: "transparent"}}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
                              <path d="M17.9789 3.5H12.75V1.25C12.75 1.05109 12.671 0.860322 12.5303 0.71967C12.3897 0.579018 12.1989 0.5 12 0.5H6C5.80109 0.5 5.61032 0.579018 5.46967 0.71967C5.32902 0.860322 5.25 1.05109 5.25 1.25V3.5H0.0210936L0 5.375H1.54688L2.48859 20.0938C2.51243 20.4744 2.68035 20.8316 2.95821 21.0929C3.23607 21.3541 3.603 21.4997 3.98438 21.5H14.0156C14.3968 21.4999 14.7636 21.3548 15.0416 21.094C15.3196 20.8333 15.4879 20.4765 15.5123 20.0961L16.4531 5.375H18L17.9789 3.5ZM5.25 18.5L4.82812 6.5H6.375L6.79688 18.5H5.25ZM9.75 18.5H8.25V6.5H9.75V18.5ZM10.875 3.5H7.125V2.1875C7.125 2.13777 7.14475 2.09008 7.17992 2.05492C7.21508 2.01975 7.26277 2 7.3125 2H10.6875C10.7372 2 10.7849 2.01975 10.8201 2.05492C10.8552 2.09008 10.875 2.13777 10.875 2.1875V3.5ZM12.75 18.5H11.2031L11.625 6.5H13.1719L12.75 18.5Z" fill="#787878"/>
                            </svg>
                          </button>
                        </div>  
                        <div className="main-info">
                          <div className="text-sm">
                            <span>{t("design.text")}:{" "}</span>
                            <span className="bold">{item.vId}</span>
                          </div>
                          <div className="text-sm mt-1">
                            <span>{t("size.text")}:{" "}</span>
                            <span className="bold">
                              {item.size.width}x
                              {item.size.type === 1 || item.size.type === 0
                                ? item.size.length
                                : item.length}
                            </span>
                          </div>
                          {/* price for per square meter in currency format */}
                          <div className="text-sm mt-1">
                            <span>{t("pricePerSM.text")}: </span>
                            <span className="bold">
                              <CurrencyFormat
                                value={item.price}
                                displayType={"text"}
                                thousandSeparator=" "
                                suffix={" " + t("suffix.text")}
                              />
                            </span>
                          </div>
                          <div className="text-sm mt-1">
                            <span>{t("DeliveryPeriod.text")}: </span>
                            <span>
                              {item.units[item.count - 1]} {t("singleDay.text")}
                            </span>
                          </div>
                        </div>
                        <div className="price-config">
                          {(item.size.type === 1 || item.size.type === 0) && (
                            <div className="less-more">
                              {/* incrementing and decrementing buttons */}
                              <button
                                className="item-button"
                                onClick={() => this.decrement(item.id)}
                              >
                               -
                              </button>
                              <span>{item.count}</span>
                              <button
                                className="item-button"
                                onClick={() => this.increment(item.id)}
                              >
                                +
                              </button>
                            </div>
                          )}
                          <div>
                            {item.discount > 0 && (
                              <span className="text-sm text-through">
                                {/* price before discount */}
                                <CurrencyFormat
                                  value={
                                    item.price *
                                    ((item.size.type === 1 ||
                                    item.size.type === 0
                                      ? item.size.length * item.size.width
                                      : parseInt(item.length) *
                                        item.size.width) /
                                      10000) *
                                    item.count
                                  }
                                  displayType={"text"}
                                  thousandSeparator=" "
                                  suffix={" " + t("suffix.text")}
                                />
                              </span>
                            )}
                            <br />
                            <span className="text bold">
                              {/* price after discount  */}
                              <CurrencyFormat
                                value={
                                  (item.price - item.discount) *
                                  ((item.size.type === 1 || item.size.type === 0
                                    ? item.size.length * item.size.width
                                    : parseInt(item.length) * item.size.width) /
                                    10000) *
                                  item.count
                                }
                                displayType={"text"}
                                thousandSeparator=" "
                                suffix={" " + t("suffix.text")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* all information releted to order sends to child component */}
          <div className="basket-right">
            <OrderCard
              dataParentToChild={this.totalPrice}
              discount={this.discount}
              delivery_day={this.delivery_day}
              delivery_price={0}
            />
          </div>
        </div>
        <div id="myModal" className={this.state.show ? `mod modalOpen` : "mod"}>
          <div className="modalContent">
            <h1 className="text-lg">{t("deletePopup.text")}</h1>
            <div className="modal-buttons pt-2">
              <PrimaryButton
                onClick={() => this.clearCookie()}
                value={t("delete.text")}
              />
              <SecondaryButton
                onClick={() => this.hideModal()}
                value={t("cancel.text")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// function for redux here called only "delet" and "clear" options

function mapDispatchToProps(dispatch) {
  return {
    DeleteCart: (id) => dispatch(DeleteCart(id)),
    ClearCart: () => dispatch(ClearCart()),
  };
}
export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(Cart))
);
