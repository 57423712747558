import React from "react";
import img from "../assets/static/img/sag.png";
import img_1 from "../assets/static/img/Our002.png";
import img_2 from "../assets/static/img/Our4.png";
import img_3 from "../assets/static/img/Sagxxl.png";
import img_4 from "../assets/static/img/Our1.png";
import img_5 from "../assets/static/img/Our2.png";
import img_6 from "../assets/static/img/Our3.png";
import img_7 from "../assets/static/img/Our001.png";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="main" style={{ marginTop: "32px" }}>
        <h2 className="bold" style={{ textAlign: "center" }}>
          {t("AboutUs.text")}
        </h2>
        <div className="container image-text-section">
          <img src={img} style={{ width: "100%" }} alt="sag" />
          <h2 style={{ letterSpacing: "1px" }} className="bold mission-text">
            {t("aboutMissionText.text")}
          </h2>
        </div>
        <div className="container about-section-container">
          <div className="about-section">
            <div className="image-container">
              <img src={img_7} alt="Building Image" />
            </div>
            <div className="text-container">
              <h2>{t("aboutHeader_1.text")}</h2>
              <p>{t("aboutText_1.text")}</p>
            </div>
          </div>
        </div>
        <div className="production-info">
          <div className="container">
            <h2>{t("aboutVolume.text")}</h2>
            <div className="years-grid">
              <div className="left-column">
                <div
                  className="d-flex justify-content-between"
                  style={{
                    borderBottom: "1px solid #d5d5d5",
                    padding: "16px 0",
                  }}
                >
                  <p>2000{t("year.text")}</p>
                  <p>1 000 000 {t("million_mkv.text")}</p>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{
                    borderBottom: "1px solid #d5d5d5",
                    padding: "16px 0",
                  }}
                >
                  <p>2010{t("year.text")}</p>
                  <p>5 000 000 {t("million_mkv.text")}</p>
                </div>
              </div>
              <div className="right-column">
                <div
                  className="d-flex justify-content-between"
                  style={{
                    borderBottom: "1px solid #d5d5d5",
                    padding: "16px 0",
                  }}
                >
                  <p>2020{t("year.text")}</p>
                  <p>12 000 000 {t("million_mkv.text")} </p>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{
                    borderBottom: "1px solid #d5d5d5",
                    padding: "16px 0",
                  }}
                >
                  <p>2024{t("year.text")}</p>
                  <p>15 000 000 {t("million_mkv.text")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="about-section two">
            <div className="text-container two">
              <h2>{t("aboutHeader_2.text")}</h2>
              <p>{t("aboutText_2.text")}</p>
            </div>
            <div className="image-container">
              <img src={img_1} alt="Building Image" />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "116px" }} className="container">
          <div className="text-container two">
            <h2>{t("aboutHeader_3.text")}</h2>
            <p>{t("aboutText_3.text")}</p>
          </div>
        </div>
        <div className="gallery-container">
          <div className="container">
            <div className="top-grid">
              <img src={img_3} alt="Thread Image 2" />
              <img src={img_4} alt="Thread Image 3" />
              <img src={img_5} alt="Thread Image 4" />
            </div>
            <div className="bottom-grid">
              <img src={img_6} alt="Thread Image 5" />
              <img src={img_2} alt="Thread Image 1" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withTranslation()(AboutPage));
