// import logo from './logo.svg';
// import './App.css';

// import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { withTranslation } from "react-i18next";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import AppHeader from "./common/header";
import AppFooter from "./common/footer";
import Post from "./common/post";
import Blogs from "./common/blogs";
import MainPage from "./views/main";
import AboutPage from "./views/about";
import MethodPage from "./views/method";
import Category from "./views/category";
import Collection from "./views/collection";
import Product from "./views/product";
import SearchOne from "./views/search_1";
import SideBar from "./views/sidebar";
import { SoonPage } from "./views/soon";

import Cart from "./views/cart";

import services from "./views/services";
import freeSize from "./views/freeSize";
import contacts from "./views/contacts";

import CheckOut from "./views/checkout";
// import OrdersInfo from "./views/ordersInfo";
import staticPages from "./views/static";

import Test from "./views/test";
import errorPage from "./common/errorPage";
import ordersInfo from "./views/ordersInfo";

const HeaderWrapper = ({ children }) => {
  const location = useLocation();
  const isMainPage = location.pathname === "/";
  const [hasScrolled, setHasScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setHasScrolled(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const headerClass = isMainPage
    ? hasScrolled
      ? "scrolled-header"
      : ""
    : "scrolled-header";

  return (
    <div id="header-wrapper" className={`header ${headerClass}`}>
      {children}
    </div>
  );
};

class App extends React.Component {
  render() {
    const { t } = this.props;
    const currentDate = new Date();
    const cutoffDate = new Date("2024-10-11");

    return (
      <Router>
        <div className="parent">
          {currentDate < cutoffDate ? (
            <SoonPage />
          ) : (
            <>
              <HeaderWrapper>
                <AppHeader
                  onUpdate={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" })
                  }
                  id="header"
                  parentData={this.cart_length}
                />
              </HeaderWrapper>
              <div className="body">
                <Switch>
                  <Route exact path="/" component={MainPage} />
                  <Route path="/about" component={AboutPage} />
                  <Route path="/method" component={MethodPage} />
                  <Route path="/category" component={Category} />
                  <Route path="/collection" component={Collection} />
                  {/* <Route path="/test" component={Test} /> */}
                  {/* <Route
                path="/product"
                component={() => (
                  <Product
                    {...this.state}
                    parentCallback={this.handleCallback}
                  />
                )}
              /> */}
                  <Route path="/product" component={Product} />

                  <Route path="/searcht" component={SearchOne} />
                  <Route path="/sidebar" component={SideBar} />

                  <Route path="/post/:id" component={Post} />
                  <Route path="/blogs" component={Blogs} />
                  <Route path="/cart" component={Cart} />
                  <Route path="/checkout" component={CheckOut} />
                  <Route path="/orderInfo/:id" component={ordersInfo} />

                  {/* <Route path="/services" component={services} /> */}
                  <Route path="/freesize" component={freeSize} />
                  <Route path="/contacts" component={contacts} />
                  <Route path="/static/:id" component={staticPages} />
                  {/* <Route  path="/analytic" component={()=>(<AnalyticPage {...this.state}/>)} /> */}
                  {/* <Route  path="/brands" component={()=>(<BrandPage {...this.state}/>)} /> */}
                  <Route exact path="*" component={errorPage} />
                </Switch>
              </div>
              <div className="footer">
                <AppFooter />
              </div>
              <section
                style={{
                  backgroundColor: "#C6B3A3",
                  color: "#fff",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <div className="container">
                  <div
                    className="d-flex justify-content-between"
                    style={{ gap: "3rem" }}
                  >
                    {/* <div className="last-footer" >
                    <p>Foydalanish shartlari</p>
                    <p>Oferta shartlari</p>
                    <p>Maxfiylik siyosati</p>
                  </div> */}
                    <div>
                      <div className="pointer">
                        <a href="https://dagger.uz/" target="_blank">
                          ©2021-{new Date().getFullYear()} {t("stire.text")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
      </Router>
    );
  }
}
export default withTranslation()(App);
