import React, { useEffect, useState } from 'react';

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{  margin:"0 12px", backgroundColor: '#D9D9D9' }}>
      <div
        style={{
          width: `${progress}%`,
          height: '5px',
          backgroundColor: '#0942C3',
          transition: 'width 0.1s',
        }}
      />
    </div>
  );
};

export default ProgressBar;
