import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

// component for rendering pagination buttons
class Paginate extends React.Component {
  // redirecting to next page
  nextPage = (offset) => {
    if (
      parseInt(offset) / parseInt(this.props.limit) !==
      Math.ceil(this.props.total / this.props.limit)
    ) {
      this.props.handlePageChange(offset);
    }
  };
  // redirecting to prev page

  prevPage = (offset) => {
    if (offset >= 0) {
      this.props.handlePageChange(offset);
    }
  };
  render() {
    const { limit, total, t, offset } = this.props;
    const pageCount = Math.ceil(total / limit);
    // genrating array for buttons
    const pages = Array.from({ length: pageCount }, (_, i) => i);
    return (
      <div>
        <ul className="pagination mt-3">
          <li
            className={
              parseInt(this.props.offset) === 0
                ? "text disabled"
                : "text pointer"
            }
          >
            {/* rendering prev button */}
            <div
              onClick={() => this.prevPage(parseInt(offset) - parseInt(limit))}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 12 22" fill="none">
                 <path d="M11 21.5L1 11L11 0.5" stroke="#01091C"/>
              </svg>
              <span className="text ml-2">{t("previous.text")}</span>
            </div>
          </li>
          {/* rendering buttons with numbers  */}
          {pages.map(
            (page) =>
              (page === 0 ||
                page === pages.length - 1 ||
                page === this.props.offset / limit + 1 ||
                page === this.props.offset / limit ||
                page === this.props.offset / limit - 1) && (
                <li
                  key={page}
                  onClick={() => this.props.handlePageChange(page * limit)}
                  className={`text page-item ${
                    page === this.props.offset / limit ? "active" : "pointer"
                  } `}
                >
                  {page + 1}
                </li>
              )
          )}
          <li
            className={
              parseInt(this.props.offset) + parseInt(limit) > total
                ? "text disabled"
                : "text pointer"
            }
          >
            {/* redirecting to next page */}
            <div
              onClick={() =>
                this.nextPage(parseInt(this.props.offset) + parseInt(limit))
              }
            >
              <span className="text mr-2">{t("next.text")}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 12 22" fill="none">
                <path d="M1 21.5L11 11L1 0.5" stroke="#01091C"/>
              </svg>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}
export default withRouter(withTranslation()(Paginate));
