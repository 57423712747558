import React from "react";

import axios from "axios";
import { Link } from "react-router-dom";

import { withRouter } from "react-router-dom";
import { BaseUrl } from "../common/api";
import { withTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import ProductCardSkeleton from "../common/productSkeleton";

import { SecondaryButton } from "../assets/static/js/Button";
import { Suspense } from "react";

const cookies = new Cookies();

const SideBar = React.lazy(() => import("./sidebar"));
const ProductCard = React.lazy(() => import("../common/product-card"));
const Paginate = React.lazy(() => import("./paginate"));

class Collection extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {
      nameUz: "",
      nameRu: "",
      limit: 21,
      offset: 0,
      total: 0,
      isLoading: true,
      notFound: false,
      filterShow: false,
      openfilter: true,
      collection: {
        products: [],
      },
      product: [],
      url: "",
    };
    this._isMounted = false;
    this.parsed = {
      "sort-by": "all"
    }
   
    // detection language
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
    } else {
      this.name = "nameRu";
    }
  }
  async componentDidMount() {
    this._isMounted = true;
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    // opening or closing sidebar
    this.filterToggle();
    //  fetching data
    this.fetchData();
  }
  async componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    this._isMounted = true;

      // opening or closing sidebar
      this.filterToggle();
      //  fetching data
      this.setState(
        {
          isLoading: true,
          category: [],
        },
        this.fetchData
      );
    }
  }
  // fetching data from api
  fetchData = async () => {
    // getting current url
    this.getQuery();
    const response = await axios.get(
      BaseUrl + `products/${this.category_id}/cat?` + this.url
    );
    let product = response.data.results;
    if (response.data.count === 0 || product.length === 0) {
      this._isMounted &&
        this.setState({
          notFound: true,
        });
    } else {
      const nameUz = product[0].collection.category_nameUz;
      const nameRu = product[0].collection.category_nameRu;
      this._isMounted &&
        this.setState({
          notFound: false,
          nameUz,
          nameRu,
        });
    }
    this._isMounted &&
      this.setState({
        product,
        total: response.data.count,
        isLoading: false,
      });
  };
  // detec changes during pagination
  handlePageChange = (offset) => {
    this._isMounted && this.setState({ offset }, this.fetchData);
    this.parsed.offset = offset;
    this.offset = offset;
    this.url = this.queryString.stringify(this.parsed, {
      skipNull: true,
      arrayFormat: "comma",
    });
    this.props.history.push(`collection?` + this.url);
  };
  handleSortChange = (sort) => {
    this.parsed = this.queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    this.parsed["sort-by"] = sort;
    this.parsed.offset = 0
    this.url = this.queryString.stringify(this.parsed, {
      arrayFormat: "comma",
    });
    this.props.history.push(`collection?` + this.url);
    
  }
  // getting current url and modify it
  getQuery = async () => {
    this.queryString = require("query-string");
    this.parsed = this.queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    this.category_id = this.parsed.category_id;
    if (this.parsed.offset) {
      this.offset = this.parsed.offset;
    } else {
      this.offset = this.state.offset;
    }
    if (this.parsed.limit) {
      this.url = this.queryString.stringify(this.parsed, {
        arrayFormat: "comma",
      });
    } else {
      this.url =
        this.queryString.stringify(this.parsed, {
          arrayFormat: "comma",
        }) + `&limit=${this.state.limit}`;
    }
  };
  // sending info to child component "Sidebar" it will work whenever width of window is lower than 576px
  filterToggle = () => {
    if (window.innerWidth < 576) {
      this._isMounted &&
        this.setState({ filterShow: false, openfilter: false });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.filterToggle);
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    const { limit, total } = this.state;

    return (
      <div className="main">
        {/* Suspens needs for lazyloading  */}
        <Suspense fallback={<div>Loading...</div>}>
          <div className="container">
            <div className="link-div">
              {/* home page redirect button */}
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                <path d="M8.5 15L1.5 8L8.5 1" stroke="#01091C"/>
              </svg>

              <p
                onClick={() => this.props.history.push("/")}
                className="text-sm pointer mr-2 ml-2"
                
              >
                {t("mainPage.text")}
              </p>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                <path d="M8.5 15L1.5 8L8.5 1" stroke="#01091C"/>
              </svg>

              {/* redirect to releted category page */}
              <p
                onClick={() =>
                  this.props.history.push(
                    `/category?category_id=${this.category_id}&nameUz=${this.state.nameUz}&nameRu=${this.state.nameRu}`
                  )
                }
                className="text-sm pointer ml-2 mr-2"
              >
                {this.state[this.name]}
              </p>
             

              <p className="text-sm ml-2">{t("products.text")}</p>
            </div>
            <div className="info pt-2 ">
              <div className="d-flex align-items-center gap-5 pointer"
                onClick={() =>
                  this.setState({
                    filterShow: !this.state.filterShow,
                    openfilter: !this.state.openfilter,
                  })
                }
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M1.83325 4.16699H18.4999" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                  <path d="M1.83325 8.25293H15.8117" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                  <path d="M1.83325 12.3389H12.5859" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                  <path d="M1.83325 16.4248H9.36013" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                </svg>
                <p style={{ color: this.state.openfilter ? "#0942C3" : "black", margin: '0 7px' }}>{t("filter.text")}</p>
               {this.state.openfilter &&( <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <path d="M1 1L9 9" stroke="#0942C3"/>
                  <path d="M1 9L9 1" stroke="#0942C3"/>
                </svg>)}
              </div>
              
           
              <div className="additional-filter">
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'all' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("all")}
                >
                 {t("sortAll.text")}
                </span>
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'isNew' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("isNew")}
                >
                    {t("sortNew.text")}
                </span>
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'isTrend' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("isTrend")}
                >
                    {t("sortTrend.text")}
                </span>
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'discount' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("discount")}
                >
                    {t("sortDiscount.text")}
                </span>
              </div>
              {/* <h1 className="text-lg title">{t("Collections.text")}</h1> */}
            </div>

            <div className="collections-container">
              {/* child component which shows filters */}
              <SideBar
                filterShow={this.state.filterShow}
                filterToggle={this.filterToggle}
              />
              {/* if filters didn't match any element notFound text will show */}
              {this.state.notFound ? (
                <h3>{t("notFound.text")}</h3>
              ) : (
                <div
                  className={` pt-2 ${
                    this.state.filterShow ? "collections" : "collections-small"
                  } `}
                >
                  {/* Skeleton for product card */}

                  {this.state.isLoading && (
                    <ProductCardSkeleton cards={this.state.limit} />
                  )}
                  {/* iterating category elements by ProductCard component */}
                  {this.state.product.map((col) => (
                    <Link key={col.id} to={`/product?product_id=${col.id}`}>
                      <ProductCard
                        name={
                          col.collection.nameUz + " " + col.name.slice(2, 6)
                        }
                        articul={"#" + col.name}
                        price={col.price}
                        image={col.image ? col.image : col.collection.image}
                        isTrend={col.isTrend}
                        isNew={col.isNew}
                        isDiscount={col.discount}
                      />
                    </Link>
                  ))}
                </div>
              )}
            </div>
            <div className="paginate"
            style={{paddingLeft:  this.state.filterShow ? "0" : "15vw" }}
            >
            <Paginate
              limit={limit}
              total={total}
              offset={this.offset}
              handlePageChange={this.handlePageChange}
            />
            </div>
          </div>
        </Suspense>
      </div>
    );
  }
}
export default withRouter(withTranslation()(Collection));
