import React from 'react';


class PrimaryButton extends React.Component {
    render() {
        const {onClick, value, className, style} = this.props;
        return (
            <button onClick={onClick} className={`base-button ${'button-primary my-2 ' + className}`} type='button' style={style} >
                {value}
            </button>
        )
    }
}

class SecondaryButton extends React.Component {
    render() {
        const {onClick, value, className, style} = this.props;
        return (
            <button onClick={onClick} className={`base-button ${'button-secondary my-2 ' + className}`} type='button' style={style}>
                {value}
            </button>
        )
    }
}

class OutlinePrimaryButton extends React.Component {
    render() {
        const {onClick, value, className, style} = this.props;
        return (
            <button onClick={onClick} className={`base-button button-primary-outline my-2 ${className}` } type='button' style={style}>
                {value}
            </button>
        )
    }
}

class OutlineSecondaryButton extends React.Component {
    render() {
        const {onClick, value, className, style} = this.props;
        return (
            <button onClick={onClick} className={`base-button button-secondary-outline my-2 ${className}`} type='button' style={style}>
                {value}
            </button>
        )
    }
}

export {PrimaryButton, SecondaryButton, OutlinePrimaryButton, OutlineSecondaryButton}