import React from "react";
import Cookies from "universal-cookie";
import OrderCard from "../common/order-card";
import axios from "axios";

import { BaseUrl } from "../common/api";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Survey from "../common/survey";
import { ClearCart } from "../Actions";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { PrimaryButton } from "../assets/static/js/Button";
import Countdown from "react-countdown";
import logo from '../assets/static/logo.svg'

const cookies = new Cookies();
var curr = new Date();

class CheckOut extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables

    this.state = {
      discount: 0,
      delivery_day: 0,
      checkout: 0,
      regions: [],
      data: [],
      button: 0,
      districts: [],
      delivery_met: [],
      order_items: [],
      name: "",
      phone: "",
      payment_met: "",
      status_pay: "",
      address: "",
      comment: "",
      order_status: "",
      region: "",
      region_id: "",
      district: "",
      delivery_method: "",
      delivery_price: 0,
      payments: [],
      show: false,
      error: false,
      baseName: "",
      date: "",
      countdown: false,
      message: "",
      invalidOTP: false,
      AlreadyOTP: false,
      MaximumOTP: false,
    };
    this._isMounted = false;
    this.verifyOTP = this.verifyOTP.bind(this);
    // detecting a language
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
    } else {
      this.name = "nameRu";
    }
  }
  async componentDidMount() {
    this._isMounted = true;
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    this.queryString = require("query-string");
    const parsed = this.queryString.parse(this.props.location.search);
    // basename needed for showing some input fields
    if (parsed["baseName"]) {
      this.baseName = parsed["baseName"];
    } else {
      this.baseName = "";
    }
    // getting order items from cookie
    this.getOrderItems();
    // fetching datas
    await axios.get(BaseUrl + "checkout/").then((res) => {
      const data = res.data;
      const regions = data["regions"];
      const delivery_met = data["delivery_met"];
      const payments = data["payments"];
      this._isMounted &&
        this.setState({
          regions,
          baseName: this.baseName,
          delivery_met,
          payments,
        });
    });
    // getting region id in order to get list of districts releted this region
    const id = parseInt(cookies.get("Region"));
    const reg = this.state.regions.find((item) => item.id === id);
    await axios.get(BaseUrl + `districts/${id}/regions/`).then((res) => {
      const districts = res.data;
      this._isMounted &&
        this.setState({
          districts,
          region: reg,
          region_id: id,
        });
    });

    let cart = [];
    // getting cookie datas and computing total price/discount, and deliveryday
    if (cookies.get("Cart")) {
      cart = cookies.get("Cart");
    }
    let finalPrice = 0;
    let totalDiscount = 0;

    cart.map((item) => {
      let price =
        item.price *
        item.count *
        ((item.size.type === 1 || item.size.type === 0
          ? item.size.length * item.size.width
          : parseInt(item.length) * item.size.width) /
          10000);
      finalPrice += price;
      return finalPrice;
    });
    cart.map((item) => {
      let discount =
        item.discount *
        item.count *
        ((item.size.type === 1 || item.size.type === 0
          ? item.size.length * item.size.width
          : parseInt(item.length) * item.size.width) /
          10000);
      totalDiscount += discount;
      return totalDiscount;
    });

    this.totalPrice = finalPrice;
    this.discount = totalDiscount;

    let day = [];

    cart.map((item) => {
      for (let i = 0; i < item.count; i++) {
        day.push(item.units[i]);
      }
      return item;
    });

    this.delivery_day = Math.max(...day);
    if (isNaN(this.delivery_day)) {
      this.delivery_day = 0;
    }
    curr.setDate(curr.getDate() + this.delivery_day);
    this.date = curr.toISOString().substring(0, 10);
    this._isMounted &&
      this.setState({
        date: this.date,
        delivery_day: this.delivery_day,
        checkout: this.totalPrice,
        discount: this.discount,
      });
  }
  // getting data which filled by user
  handleChange = (event) => {
    if (event.target.name === "delivery_method") {
      const delivery_price = this.state.delivery_met.find(
        (item) => item.id === parseInt(event.target.value)
      );
      this._isMounted &&
        this.setState({
          delivery_price: delivery_price.price,
        });
    }
    this._isMounted &&
      this.setState({
        [event.target.name]: event.target.value,
      });
  };
  // checking all essential fields before sending post method
  middlefunction = () => {
    if (this.state.baseName.length > 0) {
      if (
        this.state.name.length === 0 ||
        this.state.phone.length === 0 
      ) {
        this.setState({
          error: true,
        });
      } else {
        this.postOrder();
      }
    } else {
      if (
        this.state.name.length === 0 ||
        this.state.phone.length === 0 ||
        this.state.district.length === 0 ||
        this.state.address.length === 0
      ) {
        this.setState({
          error: true,
        });
      } else {
        this.postOrder();
      }
    }
  };
  // post user order to api
  postOrder = (event) => {
    this.setState({ countdown: true });
    const data = {
      order_items: this.state.order_items,
      name: this.state.name,
      phone: this.state.phone,
      payment_met: this.state.payment_met,
      status_pay: this.state.status_pay,
      address: this.state.address,
      comment: this.state.comment,
      region: this.state.region_id,
      district: this.state.district,
      delivery_met: this.state.delivery_method,
      expected_delivery_date: this.state.date,
    };
    axios
      .post(BaseUrl + "orders/", data)
      .then((response) => {
        this.setState({
          order_id: response.data.id,
          otp_id: response.data.otp_id,
          url: response.data.url,
        });
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        console.log(error.response);
      });
  };
  // getting data only related to order items by cookies
  getOrderItems = () => {
    let orders = [];
    let format = cookies.get("Cart");
    format.map((item) => {
      let items = {};
      // items["metric"] =
      //   item.size.type === 1 || item.size.type === 0 ? false : true;
      items["quantity"] =
        item.size.type === 1 || item.size.type === 0
          ? item.count
          : parseInt(item.length);
      items["discount"] =
        item.discount *
        ((item.size.type === 1 || item.size.type === 0
          ? item.size.length * item.size.width
          : parseInt(item.length) * item.size.width) /
          10000) *
        item.count;

      items["price"] = 
        item.price *
          ((item.size.type === 1 || item.size.type === 0
            ? item.size.length * item.size.width
            : parseInt(item.length) * item.size.width) /
            10000) *
          item.count -
        item.discount *
          ((item.size.type === 1 || item.size.type === 0
            ? item.size.length * item.size.width
            : parseInt(item.length) * item.size.width) /
            10000) *
          item.count;

      items["product"] = item.id;
      items["delivery_day"] = item.units[item.count - 1];
      orders.push(items);
      return orders;
    });
    this._isMounted &&
      this.setState({
        order_items: orders,
      });
  };
  // clearing cookie and golobal state as well
  clearCookie = () => {
    cookies.set("Cart", []);
    this.props.ClearCart();
  };
  // hide the surveyModal
  hideModal = () => {
    this._isMounted &&
      this.setState({
        show: false,
      });
    if (this.state.url) {
      this.setState({ redirectTo: true });
    }
    // before hiding the modal all information from cookie and global state are deleted
    this.clearCookie();
  };

  // verifying a one time password sended by playmobile

  verifyOTP() {
    this._isMounted &&
      this.setState({
        MaximumOTP: false,
        invalidOTP: false,
        AlreadyOTP: false,
      });
    const data = {
      otp: this.state.otp,
    };
    axios
      .post(BaseUrl + "otp/verify/" + this.state.otp_id + "/", data)
      .then((response) => {
        // this._isMounted && this.setState({ show: true });
        cookies.remove("Cart");
        if (this.state.url) {
          this.setState({ redirectTo: true });
        }
        // before hiding the modal all information from cookie and global state are deleted
        this.clearCookie();
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        console.log(error.response);
        switch (error.response.data.error) {
          case "Invalid OTP.":
            this.setState({ invalidOTP: true });
            break;
          case "Maximum attempts exceeded.":
            this.setState({ MaximumOTP: true });
            break;
          case "OTP code has expired or already verified.":
            this.setState({ AlreadyOTP: true });
            break;
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    const { countdown, order_id, invalidOTP, AlreadyOTP, MaximumOTP } =
      this.state;
    // after clearing cookie and global state user redirected to page where he can check oreder details
    if (this.state.redirectTo) {
      this.clearCookie();
      return <Redirect to={`/orderInfo/${this.state.url}`} />;
    }
    return (
      <div className="main">
        <h2 className="bold" style={{ textAlign: "center", margin: '24px 0' }}>{t("checkout.text")}</h2>
        <div className="container basket pt-2">
          <div className="basket-left p-2 d-flex">
            <div className="basket-left-top">
              {/* starting  input forms */}
              <div className="form-item">
                <div className="area flex flex-column width50">
                  <input
                    className="base-input"
                    as="input"
                    onChange={this.handleChange}
                    name="name"
                    placeholder={t("yourName.text")}
                  />
                  {this.state.error && this.state.name.length <= 0 && (
                    <p style={{ color: "red" }}>{t("fillArea.text")} </p>
                  )}
                </div>
                <div className="area flex flex-column left width50" style={{ marginRight: "0" }}>
                  <div>
                    <PhoneInput
                      country={'uz'}
                      inputProps={{
                        name: "phone",
                        placeholder: "998 (99) 123 45 67",
                        required: true,
                      }}
                      inputStyle={{
                        padding: '13px',
                        border: '0.5px solid #01091C',
                        borderRadius: '0',
                        height: '52px',
                        width: '626%',
                      }}
                      buttonStyle={{
                        display: 'none',
                      }}
                      searchStyle={{
                        display: 'none',
                      }}
                      dropdownStyle={{
                        display: 'none',
                      }}
                      value={this.state.phone}
                      onChange={(phone) => this.setState({ phone })}
                    />
                    </div>
                  {this.state.error && this.state.phone.length <= 0 && (
                    <p style={{ color: "red" }}>{t("fillArea.text")} </p>
                  )}
                </div>
              </div>
              <div className="form-item mt-1">
                {this.state.baseName.length === 0 && (
                  <div className="area flex flex-column width50">
                    <select
                      as="select"
                      className="base-input disabled"
                      onChange={this.handleChange}
                      name="region"
                      disabled
                    >
                      <option value={this.state.region_id}>
                        {this.state.region[this.name]}
                      </option>
                    </select>
                    {this.state.error &&
                      this.state.district.length <= 0 &&
                      this.state.baseName.length === 0 && (
                        <p style={{ color: "red", opacity: "0" }}>{t("fillArea.text")} </p>
                      )}
                  </div>
                )}
                {this.state.baseName.length === 0 && (
                  <div className="area flex flex-column left width50">
                    <select
                      className="base-input pointer"
                      as="select"
                      onChange={this.handleChange}
                      name="district"
                      defaultValue={t("chose.text")}
                    >
                      <option disabled value={t("chose.text")}>
                        {t("chose.text")}
                      </option>

                      {this.state.districts.map((district) => (
                        <option value={district.id} key={district.id}>
                          {district[this.name]}
                        </option>
                      ))}
                    </select>
                    {this.state.error &&
                      this.state.district.length <= 0 &&
                      this.state.baseName.length === 0 && (
                        <p style={{ color: "red" }}>{t("fillArea.text")} </p>
                      )}
                  </div>
                )}
                {this.state.baseName.length > 0 && (
                  <div className="area flex flex-column width50">
                    <input
                      className="base-input"
                      as="input"
                      name="warehouse"
                      value={this.state.baseName}
                      disabled
                    ></input>
                  </div>
                )}
                {this.state.baseName.length > 0 && (
                  <div className="area flex flex-column left width50">
                    <input
                      className="base-input"
                      type="date"
                      style={{ height: '52px' }}
                      id="day"
                      name="date"
                      onChange={this.handleChange}
                      min={this.date}
                      value={this.state.date}
                    />
                  </div>
                )}

              </div>
                {this.state.baseName.length === 0 && (
                  <div className="form-item mt-1">
                      <div className="area flex flex-column width100">
                        <input
                          className="base-input"
                          as="input"
                          onChange={this.handleChange}
                          name="address"
                          placeholder={t("address.text")}
                        />
                        {this.state.error &&
                          this.state.address.length <= 0 &&
                          this.state.baseName.length === 0 && (
                            <p style={{ color: "red" }}>{t("fillArea.text")} </p>
                          )}
                      </div>
                  </div>
                )}
              <div className="form-item mt-1">
                <div className="area flex flex-column width100">
                  <input
                    className="base-input"
                    as="input"
                    onChange={this.handleChange}
                    name="comment"
                    placeholder={t("addComment.text")}
                  />
                </div>
              </div>
              {order_id && (
                <div className="form-item mt-1" id="checkout-right-btn">
                  <div className="area flex flex-column width100">
                    <input
                      className="base-input"
                      as="input"
                      onChange={(e) => {
                        this.setState({ otp: e.target.value });
                      }}
                      name="otp"
                      placeholder={t("OTPPlaceHolder.text")}
                      type="number"
                      min={10000}
                      max={99999}
                      maxLength={5}
                      pattern="\d{5}"
                    />
                    {invalidOTP && (
                      <p style={{ color: "red" }}>{t("InvalidOTP.text")}</p>
                    )}
                    {MaximumOTP && (
                      <p style={{ color: "red" }}>{t("MaximumOTP.text")}</p>
                    )}
                    {AlreadyOTP && (
                      <p style={{ color: "red" }}>{t("AlreadyOTP.text")}</p>
                    )}
                  </div>
                </div>
              )}
              <div className="modal-buttons" id="checkout-left-btn">
                <PrimaryButton
                  onClick={() => {
                    !countdown && this.middlefunction();
                  }}
                  value={t("sendSMS.text")}
                  className={countdown && "disabled"}
                  style={{width: '100%', height: '52px', padding: '12px 32px'}}
                />
                {countdown && (
                  <Countdown
                    date={Date.now() + 180000}
                    precision={3}
                    onComplete={() => {
                      this.setState({ countdown: false });
                    }}
                    renderer={({ minutes, seconds }) => (
                      <span>
                        {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                      </span>
                    )}
                  />
                )}
                {order_id && (
                  <div className="form-item mt-1 ">
                    <div className="area flex flex-column width100">
                      <input
                        className="base-input"
                        as="input"
                        onChange={(e) => {
                          this.setState({ otp: e.target.value });
                        }}
                        name="otp"
                        placeholder={t("OTPPlaceHolder.text")}
                        type="number"
                        min={10000}
                        max={99999}
                        maxLength={5}
                        pattern="\d{5}"
                      />
                      {invalidOTP && (
                        <p style={{ color: "red" }}>{t("InvalidOTP.text")}</p>
                      )}
                      {MaximumOTP && (
                        <p style={{ color: "red" }}>{t("MaximumOTP.text")}</p>
                      )}
                      {AlreadyOTP && (
                        <p style={{ color: "red" }}>{t("AlreadyOTP.text")}</p>
                      )}
                    </div>
                  </div>
                )}
                {order_id && ( 
                  <div className="modal-buttons pt-1 mt-4">
                    <PrimaryButton
                      onClick={this.verifyOTP}
                      value={t("OTPVerify.text")}
                      style={{ padding: '12px 24px', height: '52px', marginBottom: '0', width: '100%'}}
                    />
                  </div>
                )}
              </div>

              {/* ending input forms */}
              {/* submittin button */}
            </div>
            <div className="checkout-right">
              <img src={logo} width={130} alt="logo" />
              <p>{t("checkoutNote.text")}</p>
              <div id="checkout-right-btn" className="pt-2">
                <PrimaryButton
                  onClick={() => {
                    !countdown && this.middlefunction();
                  }}
                  style={{ padding: '12px 24px', height: '52px', marginBottom: '0', width: '100%'}}
                  value={t("sendSMS.text")}
                  className={countdown && "disabled"} 
                />
                {countdown && (
                  <Countdown
                    date={Date.now() + 180000}
                    precision={3}
                    onComplete={() => {
                      this.setState({ countdown: false });
                    }}
                    renderer={({ minutes, seconds }) => (
                      <span>
                        {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                      </span>
                    )}
                  />
                )}
                {order_id && ( 
                  <div className="modal-buttons pt-1">
                    <PrimaryButton
                      onClick={this.verifyOTP}
                      value={t("OTPVerify.text")}
                      style={{ padding: '12px 24px', height: '52px', marginBottom: '0', width: '100%'}}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* OTP verifying form */}
            {/* {order_id && (
              <div className="basket-left-bottom d-flex flex-column">
                <div className="form-item mt-1">
                  <div className="flex flex-column width100">
                    <label className="text-sm text-medium">
                      {" "}
                      {t("OTP.text")}
                    </label>
                    <input
                      className="base-input"
                      as="input"
                      onChange={(e) => {
                        this.setState({ otp: e.target.value });
                      }}
                      name="otp"
                      placeholder={t("OTPPlaceHolder.text")}
                      type="number"
                      min={10000}
                      max={99999}
                      maxLength={5}
                      pattern="\d{5}"
                    />
                  </div>
                </div>
                <div className="modal-buttons pt-2">
                  <PrimaryButton
                    onClick={this.verifyOTP}
                    value={t("OTPVerify.text")}
                  />
                </div>
                {invalidOTP && (
                  <p style={{ color: "red" }}>{t("InvalidOTP.text")}</p>
                )}
                {MaximumOTP && (
                  <p style={{ color: "red" }}>{t("MaximumOTP.text")}</p>
                )}
                {AlreadyOTP && (
                  <p style={{ color: "red" }}>{t("AlreadyOTP.text")}</p>
                )}
              </div>
            )} */}
          </div>
          {/* child component which price information of order */}
          <div className="basket-right">
            <OrderCard
              dataParentToChild={this.state.checkout}
              button={this.state.button}
              discount={this.state.discount}
              delivery_day={this.delivery_day}
              delivery_price={this.state.delivery_price}
            />
          </div>
        </div>
        <div id="myModal" className={this.state.show ? `mod modalOpen` : "mod"}>
          {/* ellective survey after veryfiying OTP */}
          <div className="modalContent survery-container">
            <Survey hideModal={this.hideModal} showModal={this.showModal} />
          </div>
        </div>
      </div>
    );
  }
}
// redux function here called only clearCart function
function mapDispatchToProps(dispatch) {
  return {
    ClearCart: () => dispatch(ClearCart()),
  };
}
export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(CheckOut))
);
