import React from "react";

import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Suspense } from "react";
import Cookies from "universal-cookie";
import { OutlinePrimaryButton } from "../assets/static/js/Button";
import axios from "axios";
import banner_img  from "../assets/static/image43.png";
import { BaseUrl } from "../common/api";

const UncontrolledExample = React.lazy(() => import("../common/banner"));
const Categories = React.lazy(() => import("../common/categories"));
const Trend = React.lazy(() => import("../common/trend"));
const Advantage = React.lazy(() => import("../common/advantages"));
const Blog = React.lazy(() => import("../common/blog"));
const Faq = React.lazy(() => import("../common/faq"));
const TwoBanners = React.lazy(() => import("../common/twoBanners"));
const Blogs = React.lazy(() => import("../common/blogsWithCollections"));

const cookies = new Cookies();
class MainPage extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {
      show: true,
      lang: "",
      data: {},
    };
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    // fetching data from api
    this._isMounted && window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    document.title = "SAG";
    this._isMounted &&
      axios.get(BaseUrl + "mainpage/").then((res) => {
        const data = res.data;
        this._isMounted &&
          this.setState({
            data,
          });
      });
    // detecting the language
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    this._isMounted &&
      this.setState({
        lang: this.lang,
      });
  }
  // hiding region choosing modal shows in header
  hideModal = () => {
    this._isMounted &&
      this.setState({
        show: false,
      });
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    const { data } = this.state;
    return (
      <div className="main">
        {/* suspense for lazyload */}
        <Suspense fallback={<div>Loading...</div>}>
        {/* rendering banner */}
        {this.state.data["banner"] && (
              // <UncontrolledExample data={data["banner"]} />
              <div className="carousel">
                {/* <a href={banner.link}> */}
                  <img src={banner_img} alt="banner" />
              {/* </a> */}
              </div>
            )}
          <div>
            <section className="container category-images">
              {/* <h3 className="text-title">{t("Category.text")}</h3> */}
              {/* rendering categories */}
              {this.state.data["category"] && (
                <Categories data={data["category"]} />
              )}
            </section>
            <section style={{backgroundColor: '#C6B3A3', paddingTop: '4rem', paddingBottom: '4rem'}}>
                <Blogs />
                
                {data["post"] && <Blog data={data["post"]} className="container" />}
            </section>
            <section className="container" style={{paddingTop: '7.7rem', paddingBottom: '7.7rem'}}>
              <Faq />
            </section>
            {/* <section style={{backgroundColor: '#C6B3A3', paddingTop: '4rem', paddingBottom: '4rem'}}>
                <TwoBanners />
            </section> */}
            {/* <section className="container">
              <div className="trend-products-info">
                <h1 className="text-title">{t("Trend.text")}</h1>
                
                <OutlinePrimaryButton
                  onClick={() =>
                    this.props.history.push(`/searcht?Trend=true&offset=0`)
                  }
                  value={t("ShowAll.text")}
                  className="mobile-hidden"
                />
              </div>
             
              {data["trend"] && (
                <Trend
                  api={"Trend"}
                  data={data["trend"]}
                  collections={data["collections"]}
                />
              )}

              <div className="d-flex flex-nowrap justify-content-center mobile-appear">
         
                <OutlinePrimaryButton
                  onClick={() =>
                    this.props.history.push(`/searcht?Trend=true&offset=0`)
                  }
                  value={t("ShowAll.text")}
                />
              </div>
            </section> */}

            {/* <section className="container">
              <div className="trend-products-info">
                <h1 className="text-title">{t("Discount.text")}</h1>
               
                <OutlinePrimaryButton
                  onClick={() =>
                    this.props.history.push(`/searcht?Discount=true`)
                  }
                  value={t("ShowAll.text")}
                  className="mobile-hidden"
                />
              </div>
          
              {data["discount"] && (
                <Trend
                  api={"Discount"}
                  data={data["discount"]}
                  collections={data["collections"]}
                />
              )}

              <div className="d-flex flex-nowrap justify-content-center mobile-appear">
       
                <OutlinePrimaryButton
                  onClick={() =>
                    this.props.history.push(`/searcht?Discount=true`)
                  }
                  value={t("ShowAll.text")}
                />
              </div>
            </section> */}

            {/* <section className="container">
              <div className="trend-products-info">
                <h1 className="text-title ">{t("Advantages.text")}</h1>
              </div>
              {data["opportunity"] && <Advantage data={data["opportunity"]} />}
            </section> */}

            <section  style={{backgroundColor: '#C6B3A3', paddingBottom: "15px" }}>
              {/* <div className="blog">
                <h1 className="text-title">{t("Blog.text")}</h1>
                <OutlinePrimaryButton
                  onClick={() => this.props.history.push(`/blogs`)}
                  value={t("ShowAll.text")}
                  className="mobile-hidden"
                />
              </div> */}
              {/* {data["post"] && <Blog data={data["post"]} className="container" />} */}
              <div className="container" >
              {this.state.data["banner"] && (
              <UncontrolledExample  data={data["banner"]} />
            )}
    
             
{/* 
              <div className="d-flex flex-nowrap justify-content-center mobile-appear">

                <OutlinePrimaryButton
                  onClick={() => this.props.history.push(`/blogs`)}
                  value={t("ShowAll.text")}
                />
              </div> */}
              </div>
            </section>
          </div>
          <br />
        </Suspense>
      </div>
    );
  }
}
export default withRouter(withTranslation()(MainPage));
