import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

export default class Mapleaflet extends React.Component {
  render() {
    if (this.props.center.length > 0) {
      this.lng = this.props.center[0];
      this.lat = this.props.center[1];
    } else {
      this.lng = 69.25487263245518;
      this.lat = 41.3200984611684;
    }
    return (
      <div className="leaflet">
        {this.props.positions.length > 0 && (
          <MapContainer
            center={[this.lat, this.lng]}
            zoom={12}
            style={{ height: "400px" }}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {this.props.positions.map((item) => (
              <div key={item.id}>
                <Marker position={[item.lat, item.lng]} />
              </div>
            ))}
          </MapContainer>
        )}
      </div>
    );
  }
}
